export default {
    "tableColumn.name": "名称",
    "tableColumn.name_en": "名字 EN",
    "tableColumn.name_vi": "名字 VI",
    "tableColumn.flag": "旗帜",
    "tableColumn.menuPath": "菜单路径",
    "tableColumn.icon": "图标",
    "tableColumn.state": "状态",
    "tableColumn.sort": "排序",
    "tableColumn.publish_date": "发布日期",
    "tableColumn.creationTime": "创建时间",
    "tableColumn.changeTime": "变更时间",
    "tableColumn.operation": "操作",
    "tableColumn.interfacePath": "接口路径",
    "tableColumn.id": "序号",
    "tableColumn.username": "用户名",
    "tableColumn.nickname": "昵称",
    "tableColumn.remark": "备注",
    "tableColumn.title": "标题",
    "tableColumn.EN": "英文",
    "tableColumn.VN": "越文",
    "tableColumn.image": "封面",
    "tableColumn.url": "链接",
    "tableColumn.type": "类型",
    "tableColumn.description": "描述",
    "tableColumn.description_en": "描述 EN",
    "tableColumn.description_vi": "描述 VI",
    "tableColumn.interestRate": "利率",
    "tableColumn.maximumInvestAmount": "最大投资金额",
    "tableColumn.minimumInvestAmount": "最低投资金额",
    "tableColumn.tradeNo": "交易流水号",
    "tableColumn.memberID": "成员ID",
    "tableColumn.memberName": "成员名称",
    "tableColumn.userID": "用户id",
    "tableColumn.userInfo": "用户信息",
    "tableColumn.parentInfo": "父级信息",
    "tableColumn.startTime": "开始时间",
    "tableColumn.endTime": "结束时间",
    "tableColumn.freezeID": "冻结ID",
    "tableColumn.frozenAmount": "冻结金额",
    "tableColumn.restFrozenAmount": "剩余冻结金额",
    "tableColumn.shopName": "店铺名称",
    "tableColumn.ShopInfo": "店铺信息",
    "tableColumn.ShopOwner": "老板",
    "tableColumn.Buyer": "买方",
    "tableColumn.Receiver": "接收者",
    "tableColumn.category": "分类",
    "tableColumn.price": "价格",
    "tableColumn.rebateAmount": "回扣金额",
    "tableColumn.stock": "商品库存",
    "tableColumn.max_buy_limit": "最大购买量",
    "tableColumn.buyCount": "购买计数",
    "tableColumn.collectCount": "收集计数",
    "tableColumn.publistTime": "发布时间",
    "tableColumn.createdUser": "已创建用户",
    "tableColumn.recommend": "推荐",
    "tableColumn.logo": "标志",
    "tableColumn.address": "住址",
    "tableColumn.balance": "余额钱包",
    "tableColumn.totalBalance": "总余额",
    "tableColumn.imageSizeRemark": "图像大小备注",
    "tableColumn.count": "计数",
    "tableColumn.platform": "站台",
    "tableColumn.forceUpdate": "强制更新",
    "tableColumn.version": "版本",
    "tableColumn.member": "会员编号",
    "tableColumn.mobile": "手机号",
    "tableColumn.inviteCode": "邀请代码",
    "tableColumn.invitedCode": "Invited code",
    "tableColumn.parentMobile": "Parent mobile",
    "tableColumn.level": "会员等级",
    "tableColumn.rechargeAmount": "充值金额",
    "tableColumn.withdrawAmount": "提现金额",
    "tableColumn.avatar": "头像",
    "tableColumn.buyGoodsCount": "购买商品数",
    "tableColumn.registerTime": "注册时间",
    "tableColumn.thumbnail": "缩略图",
    "tableColumn.goods_image": "商品图片",
    "tableColumn.position": "位置",
    "tableColumn.content": "内容",
    "tableColumn.parentCategory": "父级分类",
    "tableColumn.openMerchant": "开通商户",
    "tableColumn.vipAmount": "购买金额",
    "tableColumn.coverPicture": "封面图片",
    "tableColumn.cover": "封面",
    "tableColumn.urlType": "Url类型",
    "tableColumn.term": "产品期限",
    "tableColumn.productNum": "商品数量",
    "tableColumn.productLimit": "商品数量上限",
    "tableColumn.directRecommendCount": "直推人数",
    "tableColumn.followCount": "关注数",
    "tableColumn.onlineTime": "上线时间",
    "tableColumn.failReason": "拒绝原因",
    "tableColumn.publishTime": "发布时间",
    "tableColumn.muteDay": "禁言时间",
    "tableColumn.directPushNum": "直推人数",
    "tableColumn.subDirectPushNum": "下级推荐人数",
    "tableColumn.directPushIncome": "直推收益",
    "tableColumn.subDirectPushIncome": "下级推荐收益",
    "tableColumn.totalIncome": "总收益",
    "tableColumn.userLevel": "用户等级",
    "tableColumn.agentLevel": "代理等级",
    "tableColumn.rebateRate": "返佣比例%",
    "tableColumn.levelOne": "一级",
    "tableColumn.levelTwo": "二级",
    "tableColumn.levelThree": "三级",
    "tableColumn.terminal": "终端",
    "tableColumn.amount": "金额",
    "tableColumn.financeProduct": "赠送理财商品",
    "tableColumn.color_code": "色标",
    "tableColumn.creator": "创建人员",
    "tableColumn.profitSettings": "分润设置",
    "tableColumn.profitSettingsDescription":
        "（此数值为平台分润的比例，例如40%，用户购买10000元商品，平台分润4000元，商铺的60%6000元）",
    "tableColumn.profitRatioModify": "商品平台分润比例",
    "tableColumn.advertisementPriceDaily": "每日广告价",
    "tableColumn.advertisementConfiguration": "广告配置",
    "tableColumn.applicationShopCondition": "申请商铺条件",
    "tableColumn.applicationShopConditionDescription":
        "(申请商铺必须达到的条件)",
    "tableColumn.platformProfit": "平台分润比例",
    "tableColumn.serviceCharge": "服务费",
    "tableColumn.originPrice": "原价",
    "tableColumn.bankName": "银行名称",
    "tableColumn.bankCard": "银行卡号",
    "tableColumn.bankUser": "银行用户",
    "tableColumn.fee": "手续费",
    "tableColumn.feeRate": "手续费费率",
    "tableColumn.realAmount": "实际到账金额",
    "tableColumn.updater": "更新程序",
    "tableColumn.abstract": "概括",
    "tableColumn.is_china": "是否为中国区",
    "tableColumn.usdtAddress": "USDT地址",
    "tableColumn.fundType": "资金类型",
    "tableColumn.amountType": "金额类型",
    "tableColumn.discountRate": "折扣率",
    "tableColumn.AmounDay": "金额日",
    "tableColumn.consumeBean": "消耗趣豆",
    "tableColumn.rewardBean": "奖励趣豆",
    "tableColumn.dailyStep": "每日所需步数",
    "tableColumn.minLiveScore": "最小活跃度",
    "tableColumn.taskDays": "任务天数",
    "tableColumn.canGetTimes": "可以领取次数",
    "tableColumn.canGetTimesRemark": "(0表示不限制)",
    "tableColumn.sortNum": "排序",
    "tableColumn.terms_and_conditions": "条款和条件",
    "tableColumn.mini_amount": "最小充值金额",
    "tableColumn.amount_list": "充值金额配置列表",
    "tableColumn.agent_level": "代理等级",
    "tableColumn.vipBenefit": "VIP權益",
    "tableColumn.vipBenefitEn": "VIP權益英文",
    "tableColumn.vipBenefitVn": "VIP權益越南",
    "tableColumn.directVipRecommendCount": "直VIP推人数",
    "tableColumn.shopOpen": "是否开店",
    "tableColumn.bean": "趣豆",
    "tableColumn.role": "角色",
    "tableColumn.roleName": "角色名称",
    "tableColumn.expire_flash_sale": "闪购过期",
    "tableColumn.goods": "商品",
    "tableColumn.shop": "店铺",
    "tableColumn.trade_no": "交易流水号",
    "tableColumn.product_name": "产品名称",
    "tableColumn.product_image": "产品图片",
    "tableColumn.goods_price": "产品价格",
    "tableColumn.buy_amount": "购买数量",
    "tableColumn.paid_amount": "已付金额",
    "tableColumn.delivery_charge": "快递费",
    "tableColumn.total_paid_amount": "总支付金额",
    "tableColumn.reason": "原因",
    "tableColumn.reason_change": "变更原因",
    "tableColumn.status": "状态",
    "tableColumn.message": "信息",
    "tableColumn.created_at": "创建日期",
    "tableColumn.updated_at": "更新日期",
    "tableColumn.key": "钥匙",
    "tableColumn.value": "价值",
    "tableColumn.social": "社会的",
    "tableColumn.finance_term": "财务术语",
    "tableColumn.return_term_no": "退货期限号",
    "tableColumn.total_interest": "总利息",
    "tableColumn.start_time": "开始时间",
    "tableColumn.end_time": "时间结束",
    "tableColumn.balance_income_balance": "余额和收益余额",
    "tableColumn.score_income_score": "分数和收益分数",
    "tableColumn.vip_sub_users": "VIP子用户",
    "tableColumn.question": "问题",
    "tableColumn.answer_key": "应答键",
    "tableColumn.banner": "横幅",
    "tableColumn.area": "区域",
    "tableColumn.tags": "标签",
    "tableColumn.tag": "标签",
    "tableColumn.member_id": "会员ID",
    "tableColumn.secret_key": "密钥",
    "tableColumn.en": "英语",
    "tableColumn.goods_name": "产品名称",
    "tableColumn.goods_name_en": "英文产品名称",
    "tableColumn.goods_name_vi": "越南产品名称",
    "tableColumn.goods_num": "数量",
    "tableColumn.order_status": "订单状态",
    "tableColumn.express_no": "追踪号码",
    "tableColumn.express_company": "快递公司",
    "tableColumn.service_charge": "服务费",
    "tableColumn.shopLogo": "店铺标志",
    "tableColumn.shopBanner": "店铺横幅",
    "tableColumn.shopId": "店铺编号",
    "tableColumn.shopCountry": "店铺国家",
    "tableColumn.shopMobile": "店主手机",
    "tableColumn.shopAdress": "店铺地址",
    "tableColumn.shopArea": "店铺面积",
    "tableColumn.shopStatus": "店铺状态",
    "tableColumn.shopTag": "商店标签",
    "tableColumn.receiver": "接收者",
    "tableColumn.receiver_address": "收件人地址",
    "tableColumn.receiver_province": "市/省",
    "tableColumn.receiver_district": "地区/总和",
    "tableColumn.receiver_region": "地区",
    "tableColumn.receiver_moible": "接收者手机",
    "tableColumn.shop_share_amount": "店铺份额金额",
    "tableColumn.used_bean": "用过的豆子",
    "tableColumn.shop_name": "店铺名称",
    "tableColumn.reward_score": "奖励分数",
    "tableColumn.total_score_user_can_get": "用户可以获得的总分",
    "tableColumn.total_score_user_claimed": "用户声称的总分",
    "tableColumn.total_user_finish_task": "用户完成任务总数",
    "tableColumn.total_user_claimed_score": "用户声称的总分数",
    "tableColumn.available": "可用的",
    "tableColumn.task_days": "任务日",
    "tableColumn.configuration": "配置",
    "tableColumn.watch_video": "看视频",
    "tableColumn.answer_question": "回答问题",
    "tableColumn.news": "新闻",
    "tableColumn.flash_sale": "闪购",
    "tableColumn.promote_links": "提升链接",
    "tableColumn.date": "日期",
    "tableColumn.invite_friends": "邀请朋友",
    "tableColumn.default": "默认",
    "tableColumn.answer": "回答",
    "tableColumn.option": "选项",
    "tableColumn.fact": "事实",
    "tableColumn.score": "分数",
    "tableColumn.notes": "笔记",
    "tableColumn.visit_flash_sale": "参观闪购",
    "tableColumn.num_user_can_get": "用户可以获得",
    "tableColumn.expire_time": "过期时间",
    "tableColumn.release_time": "推出日期",
    "tableColumn.total_sold": "总销量",
    "tableColumn.total_earnings": "总收益",
    "tableColumn.product": "产品",
    "tableColumn.sku": "SKU",
    "tableColumn.variations": "变化",
    "tableColumn.parent_variation": "父变异",
    "tableColumn.original_price": "原价",
    "tableColumn.product_en": "英文产品",
    "tableColumn.value_en": "英语价值",
    "tableColumn.code": "代码",
    "tableColumn.code_name": "标识名",
    "tableColumn.back_to_select": "返回选择",
    "tableColumn.add_new": "添新",
    "tableColumn.react_count": "反应计数",
    "tableColumn.charge_type": "收费类型",
    "tableColumn.video_info": "视频信息",
    "tableColumn.video": "视频",
    "tableColumn.title_en": "英文视频",
    "tableColumn.user_received_product": "用户已收到产品",
    "tableColumn.user_come_to_get_product": "用户是来获取产品的吗？",
    "tableColumn.OrderInfo": "订单信息",
    "tableColumn.sub_title": "副标题",
    "tableColumn.contractNumber": "合同编号",
    "tableColumn.partyA": "甲方",
    "tableColumn.partyB": "乙方",
    "tableColumn.partyC": "丙方",
    "tableColumn.partyD": "丁方",
    "tableColumn.investmentAmount": "投资金额",
    "tableColumn.head": "负责人",
    "tableColumn.country": "国家",
    "tableColumn.province": "省",
    "tableColumn.registerNumber": "注册号码",
    "tableColumn.email": "电子邮件",
    "tableColumn.accountName": "账户名称",
    "tableColumn.accountNumber": "账号",
    "tableColumn.branchName": "分行名称",
    "tableColumn.signature": "签名",
    "tableColumn.cycleSelection": "期间",
    "tableColumn.contractLanguage": "语言",
    "tableColumn.statusContract": "状态",
    "tableColumn.PENDING": "待处理",
    "tableColumn.SIGNED": "已签署",
    "tableColumn.FINISHED": "已完成",
    "tableColumn.PAID": "已支付",
    "tableColumn.eContractAcceptAlertTitle": "确定接受这份合同吗？",
    "tableColumn.eDeleteContractAlertTitle": "你确定要删除这份合同吗?",
    "tableColumn.eContractAlertContent": "此操作不能撤销",
    "tableColumn.chainName": "链名",
    "tableColumn.walletAddress": " 钱包地址",
    "tableColumn.THIRTY_DAYS": "30天",
    "tableColumn.NINETY_DAYS": "90天",
    "tableColumn.phoneNumber": "电话号码",
    "tableColumn.days": "天数",
    "tableColumn.city": "城市",
    "tableColumn.paymentStatus": "支付状态",
    "tableColumn.paymentTime": "支付时间",
    "tableColumn.paidAmount": "已付金额",
    "tableColumn.paymentMethod": "付款方法",
    "tableColumn.paymentDate": "付款日期",
    "tableColumn.paymentProof": "付款证明",
    "tableColumn.referance": "推荐人",
    "tableColumn.club": "俱乐部",
    "tableColumn.contactMethod": "联系方式",
    "tableColumn.contact": "联系信息",
    "tableColumn.point": "积分",
    "tableColumn.user": "用户",
    "tableColumn.income": "收益钱包",
    "tableColumn.invest_amount": "投资金额",
    "tableColumn.free_fee_withdraw_amount": "提现免费",
    "tableColumn.enable_level_grade": "开启定级",
    "tableColumn.valid": "是否有效",
    "tableColumn.is_club_owner": "是否俱乐部拥有者",
    "tableColumn.parent": "父级用户",
    "tableColumn.commission_rate": "收益率",
    "tableColumn.direct_valid_member_count": "直推用户数量",
    "tableColumn.team_invest_amount": "团队投资金额",
    "tableColumn.agent_count": "次级用户数量",
    "tableColumn.from_level": "原等级",
    "tableColumn.to_level": "变更等级",
    "tableColumn.recharge_type": "充值类型",
    "tableColumn.rate": "率",
    "tableColumn.biz": "业务类型",
    "tableColumn.order": "订单",
    "tableColumn.company": "公司",
    "tableColumn.income_rate": "收益率",
    "tableColumn.invest_rule": "投资规则",
    "tableColumn.contract": "合同",
    "tableColumn.total_amount": "总金额",
    "tableColumn.total_expend": "总支出",
    "tableColumn.total_income": "总收益",
    "tableColumn.third_party_amount": "第三方金额",
    "tableColumn.author": "作者",
    "tableColumn.like": "点赞数",
    "tableColumn.author_avatar": "作者头像",
    "tableColumn.task": "任务",
    "tableColumn.location": "位置",
    "tableColumn.rating": "评分",
    "tableColumn.passport": "护照",
    "tableColumn.nationality": "国籍",
    "tableColumn.birthday": "生日",
    "tableColumn.expire_date": "到期日",
    "tableColumn.issue_date": "发行日期",
    "tableColumn.travel": "旅游",
    "tableColumn.sort_num": "排序号",
    "tableColumn.recommend_for_home": "推荐首页",
    "tableColumn.recommend_free_travel": "推荐免费旅行",
    "tableColumn.recommend_explore": "探索推荐",
    "tableColumn.home": "首页",
    "tableColumn.free_travel": "免费旅行",
    "tableColumn.explore": "首页",
    "tableColumn.reward_ratio": "奖励比例",
    "tableColumn.learn_seconds": "学习秒数",
    "tableColumn.start_date": "开始日期",
    "tableColumn.travel_duration": "旅行时间",
    "tableColumn.travel_destination": "旅行目的地",
    "tableColumn.min_amount": "最小金额",
    "tableColumn.max_amount": "最大金额",
    "tableColumn.day_and_rate": "日收益率",
    "tableColumn.point_rate": "点率",
    "tableColumn.travel_overview": "旅行概述",
    "tableColumn.travel_information": "旅游信息",
    "tableColumn.min_invest": "最小投资",
    "tableColumn.min_deposit": "最低存款",
    "tableColumn.task_type": "任务类型",
    "tableColumn.isInvest": "是否投资",
    "tableColumn.established_date": "成立日期",
    "tableColumn.post_or_video": "帖子或视频",
    "tableColumn.post": "帖子",
    "tableColumn.price_included": "旅行价格包含",
    "tableColumn.price_excluded": "旅行价格不包含",
    "tableColumn.registration_date": "注册日期",
    "tableColumn.minimum_participants": "最小成团人数",
    "tableColumn.invest_banner_article": "文章封面",
    "tableColumn.invest_article": "文章",
    "tableColumn.coordinate": "坐标位置",
    "tableColumn.total_investment": "总投资额",
    "tableColumn.lead_amount": "领投金额",
    "tableColumn.follow_up_amount": "跟投金额",
    "tableColumn.dining": "餐饮",
    "tableColumn.accommodation": "住宿",
    "tableColumn.banner_article": "文章封面",
    "tableColumn.content_article": "文章",
    "tableColumn.club_user_ids": "俱乐部用户",
    "tableColumn.traveler": "旅行者",
    "tableColumn.travel_time": "旅行时间",
    "tableColumn.invite_code": "邀请码",
    "tableColumn.total_assets": "总资产",
    "tableColumn.fund": "资金",
    "tableColumn.note": "内容",
    "tableColumn.customer_id": "客户编号",
    "tableColumn.club_id": "俱乐部 ID",
    "tableColumn.biz_type": "业务类型",
    "tableColumn.account_type": "账户类型",
    "tableColumn.chain_type": "链类型",
    "tableColumn.passenger": "乘客",
    "tableColumn.background_image": "背景图片",
    "tableColumn.currency_type": "货币",
    "tableColumn.recharge_to_usdt_rate": "率",
    "tableColumn.deposit_amount": "充值金额",
    "tableColumn.bank": "银行",
    "tableColumn.usdt_amount": "USDT 金额",
    "tableColumn.deposit_image": "充值图片",
    "tableColumn.2fa": "代码 2FA",
    "tableColumn.service_charge_fee": "费用",
    "tableColumn.audit_time": "审核时间",
    "tableColumn.search": "搜索",
    "tableColumn.gender": "性别",
    "tableColumn.reject_reason": "拒绝原因",
    "tableColumn.booking_id": "预订ID",
    "tableColumn.totalUSDTSuccess": "总成功USDT充值",
    "tableColumn.totalRechargeAmount": "总成功充值",
    "tableColumn.totalUSDTAuditing": "总审核中USDT充值",
    "tableColumn.totalWithdrawAuditing": "总审核提现",
    "tableColumn.totalWithdrawAuditPass": "总审核通过提现",
    "tableColumn.totalWithdrawPaid": "总已打款提现",
    "tableColumn.country_code": "国家代码",
    "tableColumn.country_name": "国家名称",
    "tableColumn.bank_card": "银行卡",
    "tableColumn.symbol": "货币符号",
    "tableColumn.currency_code": "货币代码",
    "tableColumn.basic_like": "追加点赞数量",
    "tableColumn.total_like": "总点赞数",
    "tableColumn.wallet_address": "钱包地址",
    "tableColumn.recharge_origin_amount": "充值原始金额",
    "tableColumn.cash_deposit_rules": "规则",
    "tableColumn.cash_deposit_rule_desc": "规则描述",
    "tableColumn.update_rule_cash_deposit": "更新规则",
    "tableColumn.company_name": "公司名称",
    "tableColumn.usdt_address": "USDT 地址",
    "tableColumn.amount_apply": "申请金额",
    "tableColumn.balance_fee": "余额手续费",
    "tableColumn.point_fee": "积分手续费",
    "tableColumn.is_internal": "内部号",
    "tableColumn.parent_id": "父级Id",
    "tableColumn.copied": "已复制",
    "tableColumn.copy_fail": "复制失败",
    "tableColumn.popup": "弹出",
    "tableColumn.marquee": "字幕",
    "tableColumn.vod_id": "视频ID",
    "tableColumn.type_id": "类型ID",
    "tableColumn.vod_name": "视频名称",
    "tableColumn.vod_sub": "视频副标题",
    "tableColumn.vod_en": "英文名称",
    "tableColumn.vod_status": "视频状态",
    "tableColumn.vod_letter": "视频字母",
    "tableColumn.vod_tag": "视频标签",
    "tableColumn.vod_class": "视频分类",
    "tableColumn.vod_type": "视频类型",
    "tableColumn.vod_pic": "视频图片",
    "tableColumn.vod_actor": "视频演员",
    "tableColumn.vod_director": "视频导演",
    "tableColumn.vod_writer": "视频编剧",
    "tableColumn.vod_blurb": "视频简介",
    "tableColumn.vod_remarks": "视频备注",
    "tableColumn.vod_pubdate": "视频发布日期",
    "tableColumn.vod_year": "视频年份",
    "tableColumn.vod_total": "视频总集数",
    "tableColumn.vod_serial": "视频连载",
    "tableColumn.vod_area": "视频地区",
    "tableColumn.vod_lang": "视频语言",
    "tableColumn.vod_version": "视频版本",
    "tableColumn.vod_state": "视频状态",
    "tableColumn.vod_author": "视频作者",
    "tableColumn.vod_duration": "视频时长",
    "tableColumn.vod_time_hits": "视频点击数",
    "tableColumn.vod_time_make": "视频制作时间",
    "tableColumn.vod_trysee": "视频试看",
    "tableColumn.vod_content": "视频内容",
    "tableColumn.vod_play_from": "视频播放来源",
    "tableColumn.vod_play_url": "视频播放地址",
    "tableColumn.type_name": "类型名称",
    "tableColumn.vod_time_str": "视频时间",
    "tableColumn.vod_basic_info": "基本信息",
    "tableColumn.vod_basic_info_desc": "输入视频的基本信息",
    "tableColumn.vod_content_detail": "内容详情",
    "tableColumn.vod_content_detail_desc": "输入视频的内容详情",
    "tableColumn.vod_media_statistic": "媒体和统计",
    "tableColumn.vod_media_statistic_desc": "上传视频并填写统计信息",
    "tableColumn.tmdbId": "TMDB ID",
    "tableColumn.doubanId": "豆瓣电影ID",
    "tableColumn.tmdb": "TMDB",
    "tableColumn.douban": "豆瓣电影",
    "tableColumn.server": "服务器",
    "tableColumn.lang": "语言",
    "tableColumn.season": "第几季",
    "tableColumn.ad_segment": "广告片段",
    "tableColumn.Url": "链接",
    "tableColumn.duration": "持续时间",
    "tableColumn.tmdb_id": "豆瓣电影ID",
    "tableColumn.adKey": "广告",
    "tableColumn.adPosition": "广告按相对位置插入",
    "tableColumn.adMax": "取值范围0~1.0",
    "tableColumn.adMin": "取值范围0~1.0",
    "tableColumn.adSecsPlaceholder": "在第几秒播放广告",
    "tableColumn.adPositionPlaceholder":
        "取值范围0~1.0(0.5表示在视频中间插入广告)",
    "tableColumn.secs": "广告按秒数插入",
    "tableColumn.slug": "slug",
};
